import Image from "next/image";

import CallUsButton from "./CallUsButton";
import VisitShowRoomButton from "./VisitShowRoomButton";
import { ContactLink } from "@/components/navigation/ContactLink";

interface ShowroomCardProps {
  imageSrc: string;
  phone: string;
  workingHours: {
    weekdays: string;
  };
  email: string;
}

const ShowroomCard = ({ imageSrc, phone, workingHours, email }: ShowroomCardProps) => {
  return (
    <div className="overflow-hidden rounded-2xl p-2 lg:bg-white">
      {/* Image Container */}
      <div className="group relative h-[200px] w-full">
        <Image src={imageSrc} alt="Showroom" fill className="rounded-2xl object-cover" priority />
        <div className="absolute inset-0 rounded-2xl bg-slate-700/40 hover:bg-transparent"></div>
      </div>

      {/* Content Container */}
      <div className="mt-5 space-y-3">
        {/* Call Button */}
        <div className="">
          <CallUsButton phone={phone} />

          {/* <VisitShowRoomButton /> */}
        </div>

        {/* Contact Details */}
        <div className="space-y-4 p-4">
          {/* Phone */}
          <div className="flex items-center justify-between">
            <span className="inter text-sm font-normal text-primary-foreground">Phone</span>
            <ContactLink phoneNumber={phone} iconFill="transparent" className="text-primary-forground font-medium" />
          </div>

          {/* Working Hours */}
          <div className="flex items-start justify-between">
            <span className="inter text-sm font-normal text-primary-foreground">Working hours</span>
            <div className="flex flex-col text-right">
              <div className="inter text-sm font-medium text-primary-foreground">{workingHours.weekdays}</div>
              
            </div>
          </div>

          {/* Email */}
          <div className="flex items-center justify-between">
            <span className="inter text-sm font-normal text-primary-foreground">Email</span>
            <a href={`mailto:${email}`} className="text-primary-forground font-medium hover:underline">
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowroomCard;
