"use client";

import { useState } from "react";

import PhoneNumberModal from "@/components/common/modals/PhoneNumberModal";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface CallUsButtonProps {
  phone: string;
}

const CallUsButton = ({ phone }: CallUsButtonProps) => {
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);
  const isTabletOrLarger = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <button
        className="inter w-full rounded-xl bg-primary py-4 text-center font-medium text-white transition-all duration-300 hover:bg-primary-dark"
        onClick={() => {
          if (isTabletOrLarger) {
            setIsPhoneNumberModalOpen(true);
          } else {
            window.location.href = `tel:${phone}`;
          }
        }}
      >
        Call Us
      </button>
      <PhoneNumberModal
        isOpen={isPhoneNumberModalOpen}
        onClose={() => setIsPhoneNumberModalOpen(false)}
        phoneNumber={phone}
      />
    </>
  );
};

export default CallUsButton;
